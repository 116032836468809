import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';

import Layout from '../components/Layout';
import SEO from '../components/seo';
import { rhythm } from '../utils/typography';
import Gallery from '../components/Gallery';

const infoQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    logos: allFile(filter: { absolutePath: { regex: "/logo-/" } }) {
      edges {
        node {
          childImageSharp {
            fixed(width: 100) {
              ...GatsbyImageSharpFixed
            }
          }
          name
        }
      }
    }
    portfolios: allFile(filter: { absolutePath: { regex: "/portfolio-/" } }) {
      edges {
        node {
          childImageSharp {
            fixed(width: 250) {
              ...GatsbyImageSharpFixed
            }
          }
          name
        }
      }
    }
  }
`;

const Home = ({ location }) => {
  const {
    site: {
      siteMetadata: { title: siteTitle },
    },
    logos: { edges: logos },
    portfolios: { edges: portfolios },
  } = useStaticQuery(infoQuery);

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="David Táboas" keywords={[`david táboas`]} />
    </Layout>
  );
};

export default Home;
